// Adding number to skills list

const skills = document.querySelectorAll('.competence-number');
const skillsLength = skills.length;
skills.forEach((skill, index) => {
	const num = index + 1;
	skill.append(num);
});

// Create cookie to get the Browser Language

const userLang = navigator.language || navigator.userLanguage;
document.cookie = `nf_lang = ${userLang}`;
