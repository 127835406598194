// Remove language when header is sticky

const stickyElm = document.querySelector('header');

const observer = new IntersectionObserver(
	([e]) => e.target.classList.toggle('isSticky', e.intersectionRatio < 1),
	{ threshold: [1] }
	);

observer.observe(stickyElm);

// Menu opening

const menuBurger = document.querySelector('.responsive-menu');
const menuOverlay = document.querySelector('.menu-overlay');
const bodyTag = document.querySelector('body');
const headerTag = document.querySelector('header')

menuBurger.addEventListener('click', () => {
	const headerHeight = headerTag.clientHeight
	menuOverlay.classList.toggle('visible');
	menuOverlay.style.transform = `translateY(${headerHeight}px)`;
});