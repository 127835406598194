// Smooth Scroll to anchors
document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
  anchor.addEventListener("click", function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute("href")).scrollIntoView({
      behavior: "smooth",
    });
  });
});

// Parallax on shapes
const sections = document.querySelectorAll("section, footer");

const addParallax = () => {
  // this is the distance from the top
  const topViewport = window.pageYOffset;
  const midViewport = topViewport + window.innerHeight / 2;

  sections.forEach((section) => {
    const topSection = section.offsetTop;
    const midSection = topSection + section.offsetHeight / 2;
    // we want to find the distance between midViewport & midSection
    const distanceToSection = midViewport - midSection;

    const parallaxScaleTags = section.querySelectorAll(`[data-parallax-scale]`);
    const parallaxYTags = section.querySelectorAll(`[data-parallax-y]`);
    const parallaxXTags = section.querySelectorAll(`[data-parallax-x]`);

    parallaxXTags.forEach((tag) => {
      const speed = parseFloat(tag.getAttribute("data-parallax-x")); // "0.15"
      tag.style.transform = `translate(${distanceToSection * speed}px, 0)`;
    });

    parallaxYTags.forEach((tag) => {
      const speed = parseFloat(tag.getAttribute("data-parallax-y")); // "0.15"
      tag.style.transform = `translate(0, ${distanceToSection * speed}px)`;
    });

    parallaxScaleTags.forEach((tag) => {
      const speed = parseFloat(tag.getAttribute("data-parallax-scale") / 1000); // "0.15"
      let scale = 1 + distanceToSection * speed;
      tag.style.transform = `scale(${scale})`;
    });
  });
};

const wrapper = document.querySelector(".wrapper");
const bodyTag = document.querySelector("body");

addParallax();

document.addEventListener("scroll", () => {
  addParallax();
});

document.addEventListener("resize", () => {
  addParallax();
});

// Adding { }* characters on links

const links = document.querySelectorAll(".realisation a, .agence a");

links.forEach((link) => {
  const linkContent = link.innerHTML;
  const span = document.createElement("span");
  span.classList.add("border-b");
  link.innerHTML = "{ ";
  span.innerHTML = linkContent;
  link.append(span);
  link.append(" }*");
});

// For realisations, image appears on hover. For Desktop only.

const realisations = document.querySelectorAll(".realisation, .agence");

realisations.forEach((realisation) => {
  const link = realisation.querySelector("a");
  const image = realisation.querySelector("img.hover-image-el");
  if (link) {
    link.addEventListener("mouseover", () => {
      image.classList.add("appear");
    });
    link.addEventListener("mouseout", () => {
      image.classList.remove("appear");
    });
  }
});

// Fadein when text appears
const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio >= 0.1) {
        entry.target.classList.add("in-view");
      } else {
        entry.target.classList.remove("in-view");
      }
    });
  },
  {
    threshold: [0, 0.1, 1],
  }
);

const headers = document.querySelectorAll("h2,h3,h4,#competences li");
const pBig = document.querySelectorAll("p.big");

headers.forEach((header) => {
  observer.observe(header);
});

realisations.forEach((realisation) => {
  observer.observe(realisation);
});

pBig.forEach((realisation) => {
  observer.observe(realisation);
});
